import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Layout/SidebarVendor";
import StoreNavBar from './StoreNavBar'
import {
  getEventList,
  editStoreFront,
  getvendorquestionsadmin,
  getAttribute,
  getSubAttribute,
} from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";



const AdditionalDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const myEvents = useSelector(getEventList);

  //static data from database
  const [questions, setquestions] = useState([]);
  const [initattributes, setinitattributes] = useState(null);
  const [initsubattributes, setinitsubattributes] = useState(null);


  const [prefered_sub_services, setprefered_sub_services] = useState([]);
  const [attributes, setattributes] = useState([]);
  const [sub_attributes, setsub_attributes] = useState([]);
  const [categoryId, setcategoryId] = useState("");




  const getAttributes = async () => {
    try {
      console.log('categoryId', categoryId)
      setinitattributes(null);
      const response = await dispatch(getAttribute({ id: categoryId })).unwrap();
      console.log('response21212', response)
      setinitattributes(response?.data);
    } catch (rejectedValueOrSerializedError) { }
  };

  const getSubAttributes = async () => {
    try {

      setinitsubattributes(null);
      const response = await dispatch(getSubAttribute({ id: categoryId })).unwrap();

      console.log("asdassasa", response);
      setinitsubattributes(response?.data);
    } catch (rejectedValueOrSerializedError) { }
  };

  const getQuestions = async () => {
    try {

      setquestions(null);
      const response = await dispatch(getvendorquestionsadmin({ id: categoryId })).unwrap();

      console.log("asdassasa", response);
      setquestions(response?.data);
    } catch (rejectedValueOrSerializedError) { }
  };




  useEffect(() => {



    getAttributes()
    getSubAttributes()
    getQuestions()





  }, [categoryId]);


  useEffect(() => {


    if (myEvents) {
      console.log('myEvents', myEvents)
      setprefered_sub_services(myEvents?.sub_services)
      setattributes(myEvents?.attributes?.map((m) => m?._id))
      setsub_attributes(myEvents?.sub_attributes?.map((m) => m?._id))
      setcategoryId(myEvents?.prefered_services[0]?._id)
    }



  }, [myEvents]);


  console.log('myEvents', myEvents)



  const handleSkillChange4 = (event, id, type, removeradioids) => {



    let updatedSubServices = [...prefered_sub_services];


    if (type === "radio") {
      const existingObjectIndex = updatedSubServices.findIndex((obj) => obj?._id?._id === id?._id);

      if (existingObjectIndex !== -1) {
        let idsFinal = removeradioids?.map((id) => id._id);
        const ids = idsFinal.filter((service) => service !== event);

        let existingObject = updatedSubServices[existingObjectIndex];

        // Create a new object with the same properties as existingObject
        existingObject = { ...existingObject, item: existingObject.item.filter(itemId => !ids.includes(itemId)) };

        if (!existingObject.item.includes(event)) {
          existingObject.item.push(event);
        }

        if (existingObject.item.length === 0) {
          updatedSubServices.splice(existingObjectIndex, 1);
        } else {
          // Update the existing object in the array with the new object
          updatedSubServices[existingObjectIndex] = existingObject;
        }
      } else {
        console.log('yesssssssss')
        updatedSubServices.push({ _id: id, item: [event] });
      }

      console.log('updatedSubServices', updatedSubServices);
    }


    else {


      const existingObjectIndex = updatedSubServices.findIndex((obj) => obj?._id?._id === id?._id);

      if (existingObjectIndex !== -1) {

        const existingObject = updatedSubServices[existingObjectIndex];
        const answerIndex = existingObject.item.indexOf(event);

        if (answerIndex !== -1) {

          existingObject.item.splice(answerIndex, 1);


          if (existingObject.item.length === 0) {
            updatedSubServices.splice(existingObjectIndex, 1);
          }
        } else {

          existingObject.item.push(event);
        }
      } else {

        updatedSubServices.push({ _id: id, item: [event] });
      }
    }

    setprefered_sub_services(updatedSubServices);
  };






  const handleSkillChange2 = async (eventid) => {


    console.log('eventid', eventid)
    if (!attributes?.includes(eventid)) {
      setattributes([...attributes, eventid]);
    } else {
      const updatedEventIds = attributes?.filter((id) => id !== eventid);
      setattributes(updatedEventIds);
    }

  };


  const handleSkillChange3 = async (eventid) => {

    if (!sub_attributes?.includes(eventid)) {
      setsub_attributes([...sub_attributes, eventid]);
    } else {
      const updatedEventIds = sub_attributes?.filter((id) => id !== eventid);
      setsub_attributes(updatedEventIds);
    }
  };




  const saveBranch = async (e) => {
    e.preventDefault();



    try {


      const requestData = {
        _id: myEvents?._id,
        path: location.pathname,
        sub_services: JSON.stringify(prefered_sub_services),
        attributes: JSON.stringify(attributes),
        sub_attributes: JSON.stringify(sub_attributes)
      };



      const nav = await dispatch(
        editStoreFront(requestData)
      ).unwrap();

      if (nav) {
        navigate("/contact-details")
      }

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  console.log('prefered_sub_services', prefered_sub_services)


  return (
    <>
      <section className="wf-vendor-business-details">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* <StoreNavBar /> */}
              {/* <div className="d-flex gap-3 align-items-center mb-4">
                      <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/business-details-icon.svg`} alt="" className="events-icon" />
                      <h2 className="font-size-36 playfair-font color-black mb-0">{t('Additional')} {t('Details')}</h2>
                    </div> */}
              <h2 className="font-size-30 dm-font purple-color mb-4 fw-normal">{t('Additional')} {t('Details')}</h2>
              <div className="row gy-3">
                <div className={`col-lg-12 col-md-12 ${questions?.length > 0 ? "col-lg-12 col-md-12" : "col-md-12"}`}>
                  <div className="wf-shadow-2 white-bg p-4 rounded">

                    {initattributes?.length > 0 ? (
                      <>
                        <p class="font-size-20 dm-font color-black mb-3">{t('WhichAttributesbestsuitsyou')}{" "}</p>
                        <div className="d-flex gap-2 flex-wrap mb-4">
                          {initattributes ? initattributes[0]?.subcategories?.map((s) => (
                            <>
                              <input class="btn-check" type="checkbox" name="attributes" id={s._id} value={s._id} checked={attributes?.includes(s._id)} onChange={() => handleSkillChange2(s?._id)} />
                              <label class="btn wf-select-btn" for={s._id}>{s.name}</label>
                            </>
                          )) : null}
                        </div>
                      </>
                    ) : null}

                    {initsubattributes?.length > 0 ? (
                      <>
                        <p class="font-size-20 dm-font pink-color mb-2">{t('Whataretheextra')}{" "}</p>
                        <div className="d-flex gap-2 flex-wrap mb-4">
                          {initsubattributes[0]?.subcategories?.map((s) => (
                            <>
                              <input class="btn-check" type="checkbox" name="sub-attributes" id={s._id} value={s._id} checked={sub_attributes?.includes(s._id)} onChange={() => handleSkillChange3(s?._id)} />
                              <label class="btn wf-select-btn" for={s._id}>{s.name}</label>
                            </>))}
                        </div>
                      </>) : null}

                  </div>
                </div>

                {questions?.length > 0 && questions?.map((q) => (
                  <>
                    <div className="col-lg-12 col-md-12">
                      <div className="wf-shadow-2 white-bg p-4 rounded">
                        <p class="font-size-20 dm-font color-black mb-3">{q?.questionVendor}</p>
                        <div className="d-flex gap-2 flex-wrap mb-4">
                          {q?.options?.map((s) => (
                            <>
                              <input class="btn-check" type={q?.type} id={s?._id} value={s?._id} name="questions" checked={prefered_sub_services?.some((obj) => obj?._id?._id == q?._id && obj.item?.includes(s?._id))} onChange={() => handleSkillChange4(s?._id, q, q?.type, q?.options)} />
                              <label class="btn wf-select-btn" for={s._id}>{s.name}</label>
                            </>))}
                        </div>
                      </div>
                    </div>
                  </>
                ))}


                <div className="col-lg-12 col-md-12 text-end">
                  <button onClick={saveBranch} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">{t('Save')}</button>
                </div>

              </div>

            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default AdditionalDetails