import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Layout/SidebarVendor";
import { CalendarDays, MapPin, Users, MessagesSquare, Banknote } from "lucide-react";
import Modal from "react-modal";
const customStyles = {
    content: {
        "display": "block",
        "--bs-modal-width": "35%",
    },
};
Modal.setAppElement("#root");



const NewBookingDetails = () => {

    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
    }
    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                className="modal fade show wf-modal tasks"
            >
                <div class="modal-dialog modal-dialog-centered wf-modal-dialog">

                    <div class="modal-content">
                        <div class="modal-header border-0 align-items-start">
                            <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body pb-4">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <h1 class="font-size-36 playfair-font purple-color">Add Event To Send Invite</h1>
                                <p className="font-size-16 color-black mb-0">To invite your partner, first add your event.</p>
                            </div>
                        </div>
                        <div class="modal-footer border-0 justify-content-center align-items-center">
                            <button type="button" class="btn wf-btn-2 wf-pink-btn py-2 rounded-2">Add Event</button>                        </div>
                    </div>
                </div>
            </Modal>

            <section className="wf-vendor-bookings">
                <div className="container-fluid">
                    <div className="d-flex position-relative">
                        <Sidebar />
                        <section className="home-section">
                            {/* <StoreNavBar /> */}
                            <div className="d-flex gap-3 align-items-center mb-4">
                                <h2 className="font-size-36 purple-color mb-0 dm-font">Booking Details</h2>
                            </div>
                            <div className="row">
                                <div className="col-12 faq-list">
                                    <div className="wf-shadow-2 white-bg rounded-3 py-4 mb-3">

                                        {/* Row 1 */}
                                        <div className='row px-4 mb-4'>
                                            <div className='col-12'>
                                                <h3 className="dm-font font-size-20 mb-3">Couple Details</h3>
                                                <div className="d-flex justify-content-between gap-2 gap-md-5">
                                                    <div className='d-flex flex-md-row flex-column flex-wrap gap-3 gap-md-5'>
                                                        <div className='d-flex flex-lg-row flex-column gap-2 gap-md-4'>
                                                            <div className='wf-up-avatar'>
                                                                <div className='avatar-couple-first'>
                                                                    <img width="75" height="75" alt="" src="https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg" className="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3 className="font-size-30 dm-font fw-normal purple-color mb-0">Salman Habib</h3>
                                                                <p className='font-size-12 fw-normal color-grey mb-0'><span>Groom</span></p>
                                                                <p className='font-size-12 purple-color mb-0'>+971 1233 7362
                                                                    <br />salman@wedding.com
                                                                </p>
                                                                <div className="font-size-12 purple-color mb-0">
                                                                    <span className="cursor-pointer" onClick={openModal}><u>Send Invite</u></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-lg-row flex-column gap-2 gap-md-4'>
                                                            <div className='wf-up-avatar'>
                                                                <div className='avatar-couple-first'>
                                                                    <img width="75" height="75" alt="" src="https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg" className="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3 className="font-size-30 dm-font fw-normal pink-color mb-0">Hadeel Sheikh </h3>
                                                                <p className='font-size-12 fw-normal color-grey mb-0'><span>Bride</span></p>
                                                                <p className='font-size-12 pink-color mb-0'>+971 1233 7362
                                                                    <br />hadeel@wedding.com
                                                                </p>
                                                                <div className="font-size-12 pink-color mb-0">
                                                                    <span className="cursor-pointer" onClick={openModal}><u>Send Invite</u></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <a className="font-size-16 pink-color text-end mb-0" href="#"><MessagesSquare stroke="var(--color4)" size={32} /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom w-100 mb-4"></div>
                                        {/* Row 2 */}
                                        <div className='row px-4 mb-4'>
                                            <div className='col-12'>
                                                <h3 className="dm-font font-size-20 mb-3">Booking Agreement</h3>
                                                <p className="font-size-16 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                            </div>
                                        </div>
                                        <div className="border-bottom w-100 mb-4"></div>
                                        {/* Row 3 */}
                                        <div className='row px-4 mb-4'>
                                            <div className='col-12'>
                                                <h3 className="dm-font font-size-20 mb-3">Event Details</h3>
                                                <div className="d-flex flex-wrap gap-5">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div><Users stroke="var(--color4)" size={32} /></div>
                                                        <div>
                                                            <p className="font-size-16 color-grey mb-0">Number of Guests</p>
                                                            <p className="font-size-14 mb-0">200</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div><CalendarDays stroke="var(--color4)" size={32} /></div>
                                                        <div>
                                                            <p className="font-size-16 color-grey mb-0">Booking Date</p>
                                                            <p className="font-size-14 mb-0">18-04-2024</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div><CalendarDays stroke="var(--color4)" size={32} /></div>
                                                        <div>
                                                            <p className="font-size-16 color-grey mb-0">Booking Due Date</p>
                                                            <p className="font-size-14 mb-0">22-04-2024</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div><MapPin stroke="var(--color4)" size={32} /></div>
                                                        <div>
                                                            <p className="font-size-16 color-grey mb-0">Location</p>
                                                            <p className="font-size-14 mb-0">Jeddah, Saudi Arabia</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom w-100 mb-4"></div>
                                        {/* Row 4 */}
                                        <div className='row px-4 mb-4'>
                                            <div className='col-12'>
                                                <h3 className="dm-font font-size-20 mb-3">Booking Terms</h3>
                                                <div className="row">
                                                    <div className="col-md-6 col-xl-4">
                                                        <div className="d-flex gap-2">
                                                            <div><Banknote stroke="var(--color4)" size={32} /></div>
                                                            <div>
                                                                <p className="color-grey font-size-14 mb-0">Total Price</p>
                                                                <p className="color-black font-size-16">AED 7500</p>
                                                                <p className="color-grey font-size-14 mb-0">Booked on</p>
                                                                <p className="color-black font-size-16">20 April 2023</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-xl-4">
                                                        <div className="d-flex gap-2">
                                                            <div><Banknote stroke="var(--color4)" size={32} /></div>
                                                            <div>
                                                                <p className="color-grey font-size-14 mb-0">Initial Payment</p>
                                                                <p className="color-black font-size-16">AED 2500</p>
                                                                <div className="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 mb-2">
                                                                    <p className="font-size-14 white-color mb-0 text-uppercase">Mark as paid</p>
                                                                </div>
                                                                <p className="color-grey font-size-14 mb-0">Paid on</p>
                                                                <p className="color-black font-size-16">20 April 2023</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-xl-4">
                                                        <div className="d-flex gap-2">
                                                            <div><Banknote stroke="var(--color4)" size={32} /></div>
                                                            <div>
                                                                <p className="color-grey font-size-14 mb-0">Final Payment</p>
                                                                <p className="color-black font-size-16">AED 2500</p>
                                                                <div className="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 mb-2">
                                                                    <p className="font-size-14 white-color mb-0 text-uppercase">Mark as paid</p>
                                                                </div>
                                                                <p className="color-grey font-size-14 mb-0">Paid on</p>
                                                                <p className="color-black font-size-16">20 April 2023</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom w-100 mb-4"></div>
                                        {/* Row 5 */}
                                        <div className='row px-4 mb-4'>
                                            <div className='col-12'>
                                                <h3 className="dm-font font-size-20 mb-3">Payment Mode</h3>
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className="color-white wf-shadow-2 py-2 px-3 rounded wf-pink-btn">
                                                        <p className="font-size-14 white-color mb-0 text-uppercase">Cash</p>
                                                    </div>
                                                    <div className="">
                                                        <p className="font-size-12 purple-color mb-0 text-uppercase"><u>Bank Transfer</u></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>


                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewBookingDetails