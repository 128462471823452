import React  from 'react';
import ReactDOM from 'react-dom/client';
 import App from './App';
import { Provider } from 'react-redux';
import Store from "./store"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  
import { I18nextProvider } from 'react-i18next';
import i18n from './components/i18n';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={Store}>
    <I18nextProvider i18n={i18n}>
      <App />
      <ToastContainer
        autoClose={1500}
        newestOnTop={true}
      />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
); 