import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {
  getEventList, getCities, getAllCountries, getAllAreas, editStoreFront
} from "../../store/slices/userSlice";
import L from 'leaflet';
import Sidebar from "../../components/Layout/SidebarVendor";
import StoreNavBar from "./StoreNavBar";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import StoreMap from "./StoreMap";
import { useTranslation } from "react-i18next";

const LocationMap = () => {
  const { t } = useTranslation();
  const mapIcon = new L.Icon({
    iconUrl: '../assets/images/pin-map.svg',
    iconRetinaUrl: '../assets/images/pin-map.svg',
    iconAnchor: new L.Point(0, 0),
    popupAnchor: new L.Point(16, 0),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(32, 32),
    className: 'weddified-map-icon'
  });

  const myEvents = useSelector(getEventList);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const l = useLocation();



  const [markers, setmarkers] = useState([])
  const [initialPosition, setinitialPosition] = useState([])
  const [value, setvalue] = useState(null);

  const [countries, setcountries] = useState(false);
  const [cities, setcities] = useState([]);
  const [areas, setareas] = useState([]);
  const [prevCountry, setPrevCountry] = useState(null);
  const [showcountry, setshowcountry] = useState("");
  const [showcity, setshowcity] = useState("");
  const [showarea, setshowarea] = useState("");


  const [pobox_no, setpobox_no] = useState("");
  const [address, setaddress] = useState("");
  const [country, setcountry] = useState([]);
  const [mycities, setmycities] = useState([]);
  const [myareas, setmyareas] = useState([]);
  const [location, setlocation] = useState("");
  const [lat, setlat] = useState(0);
  const [long, setlong] = useState(0);


  if (value) {
    console.log('valueee', value)
    geocodeByPlaceId(value?.value?.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log('lat', lat)
        setlat(lat);
        setlong(lng);
        setlocation(value?.label);
      })
      .catch((error) => console.error(error));
  }


  const zoomLevel = 13;
  const mapRef = useRef(null);
  useEffect(() => {

    if (mapRef.current) {
      const map = mapRef.current.leafletElement;
    }
  }, [initialPosition]);





  const getCity = async () => {
    try {
      const response = await dispatch(getCities()).unwrap();

      setcities(response?.data);

    } catch (rejectedValueOrSerializedError) { }
  };


  const handleCountryChange = (e) => {
    console.log('e.target.value', e.target.value);

    if (prevCountry !== e.target.value) {
      setPrevCountry(e.target.value);
      setcountry([e.target.value]);
      setmycities(['']);
    }
  };


  const Areas = async () => {
    try {
      setareas(null);
      const response = await dispatch(getAllAreas()).unwrap();
      console.log('responsessss', response?.data)
      setareas(response?.data);

      if (response?.data) {
        const count = response?.data.length;

        setareas(response?.data);
      }

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log('initialPosition', initialPosition)

  useEffect(() => {
    if (myEvents) {

      setpobox_no(myEvents?.pobox_no)
      setaddress(myEvents?.address)
      setcountry(myEvents?.country?.map((m) => m?._id))
      setmycities(myEvents?.cities?.map((m) => m?._id))
      setmyareas(myEvents?.areas?.map((m) => m?._id))
      setlocation(myEvents?.location?.location)
      setlat(myEvents?.location?.coordinates[1])
      setlong(myEvents?.location?.coordinates[0])


      if (myEvents?.location !== null) {
        setmarkers([{ lat: myEvents?.location?.coordinates[1], lng: myEvents?.location?.coordinates[0], name: myEvents?.store_name }])
        setinitialPosition([myEvents?.location?.coordinates[1], myEvents?.location?.coordinates[0]])
      }
    }
  }, [myEvents]);


  useEffect(() => {

    setmarkers([{ lat: lat, lng: long, name: myEvents?.store_name }])
    setinitialPosition([lat, long])

  }, [location, lat, long]);


  useEffect(() => {
    getCity();
    Countries();
    Areas();
  }, []);


  const aa = [24.8607343, 67.0011364]


  const handleSave = async (e) => {
    e.preventDefault();



    try {

      const requestData = {
        _id: myEvents?._id,
        path: l.pathname,
        pobox_no: pobox_no,
        address: address,
        country: JSON.stringify(country),
        cities: JSON.stringify(mycities),
        areas: JSON.stringify(myareas),
        location: location,
        lat: lat,
        long: long
      };

      const nav = await dispatch(
        editStoreFront(requestData)
      ).unwrap();

      if (nav) {
        navigate("/faqs")
      }



    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };




  return (
    <>
      <section className="wf-vendor-location-map">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* <StoreNavBar /> */}
              {/* <div className="d-flex gap-3 align-items-center mb-4">
                  <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/location-map-icon.svg`} alt="" className="events-icon" />
                  <h2 className="font-size-36 playfair-font color-black mb-0">{t('LocationandMaps')}</h2>
                </div> */}

              <h2 className="font-size-30 dm-font purple-color mb-4 fw-normal">{t('LocationandMaps')}</h2>
              <div className="wf-shadow-2 white-bg p-4 rounded mb-4">

                <div className="row">
                  <div className="col-lg-12 col-md-12 wf-businesformpricing">
                    <h2 className="font-size-20 dm-font color-black mb-3">{t('Address')}</h2>

                    <div className="row">
                      <div className="col-xl-6 col-md-12">
                        <div class="form-floating wf-input wf-input-101 mb-3">
                          <input onChange={(e) => setaddress(e.target.value)} defaultValue={address && address} type="text" class="form-control font-size-16 px-3" id="address-line-1" placeholder="Address"
                            autocomplete="address" required />
                          <label for="address-line-1" className="form-label fw-medium font-size-14 pink-color text-uppercase px-3">{t('Address')}</label>
                        </div>
                      </div>

                      <div className="col-xl-6 col-md-12">
                        <div class="form-floating wf-input wf-input-101 mb-3">
                          <input onChange={(e) => setpobox_no(e.target.value)} defaultValue={pobox_no && pobox_no} type="text" class="form-control font-size-16 px-3" id="po-box" placeholder="PO Box No."
                            autocomplete="PO-Box-No" required />
                          <label for="po-box" className="form-label fw-medium font-size-14 pink-color text-uppercase px-3">{t('POBoxNo')}.</label>
                        </div>
                      </div>

                      <div className="col-xl-6 col-md-12">
                        <div class="dropdown mb-3 country-city-dropdown wf-dropdown-2 wf-shadow-2 white-bg rounded px-3 py-2">
                          <label class="form-label fw-medium font-size-14 pink-color mb-0">{t('Country')}</label>
                          <select onChange={(e) => handleCountryChange(e)} id="country" name="country" class="form-select form-control font-size-16 fw-medium custom-select pt-0 ps-0">
                            <option value={[]}>Select Country</option>
                            {countries?.length > 0 && countries?.map(c => (<option value={c._id} selected={country?.includes(c?._id) ? true : false}>
                              {c?.name}
                            </option>))}
                          </select>
                        </div>
                      </div>

                      {country && country[0] !== '' && country?.length > 0 && cities?.filter((ci) => country?.includes(ci.country_id))?.length > 0 && (
                        <div className="col-xl-6 col-md-12">
                          <div class="dropdown mb-3 country-city-dropdown wf-dropdown-2 wf-shadow-2 white-bg rounded px-3 py-2">
                            <label class="form-label fw-medium font-size-14 pink-color mb-0">{t('City')}</label>
                            <select onChange={(e) => setmycities([e.target.value])} id="city" name="city" class="form-select form-control font-size-16 fw-medium custom-select pt-0 ps-0">
                              <option value={[]}>Select City</option>
                              {cities?.filter((ci) => country?.includes(ci.country_id))?.map((c) => (
                                <option selected={mycities?.includes(c?._id) ? true : false} value={c._id}>
                                  {c?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>)}

                      {mycities && mycities[0] !== '' && country[0] !== '' && areas?.filter((ci) => mycities?.includes(ci?.city_id?._id))?.length > 0 && (
                        <div className="col-xl-6 col-md-12">
                          <div class="dropdown mb-3 country-city-dropdown wf-dropdown-2 wf-shadow-2 white-bg rounded px-3 py-2">
                            <label class="form-label fw-medium font-size-14 pink-color mb-0">{t('Area')}</label>
                            <select onChange={(e) => { console.log('e.target.value', e.target.value); setmyareas([e.target.value]) }} id="area" name="area" class="form-select form-control font-size-16 fw-medium custom-select pt-0 ps-0">
                              <option value={[]}>Select Area</option>
                              {areas?.filter((ci) => mycities?.includes(ci?.city_id?._id))?.map((c) => (<option selected={myareas?.includes(c?._id) ? true : false} value={c?._id}>
                                {c?.name}
                              </option>))}
                            </select>
                          </div>
                        </div>)}

                      <div className="col-xl-6 col-md-12">
                        <div className="wf-google-input col-md-12 mb-3 wf-shadow-2 white-bg rounded px-3 py-2">
                          <label class="form-label fw-medium font-size-14 pink-color mb-0">{t('Location')}</label>
                          <GooglePlacesAutocomplete apiKey={process.env.REACT_GOOGLE_MAPS_API_KEY}
                            selectProps={{ value, onChange: setvalue, placeholder: location ? location : "Enter your location" }} />
                        </div>
                      </div>

                    </div>


                  </div>
                  <div className="col-lg-12 col-md-12">
                    <h2 className="font-size-20 dm-font color-black mb-3">{t('Map')}</h2>


                    <StoreMap markers={markers} initialPosition={initialPosition} myEvents={myEvents} />








                    <div className="text-end">
                      <button onClick={handleSave} class="btn btn-lg wf-pink-btn wf-btn fw-bold text-uppercase py-2">{t('Save')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  )
}


export default LocationMap