import { toast } from "react-toastify";
import axios from "axios";
import {
  privacy,
  terms,
  rejectRequest,
  editStoreFront,
  signinUser,
  verifyAccount,
  addNewsLetter,
  updateRSVP,
  inviteGuest,
  updateProfile,
  getAllVenues,
  saveAttachment,
  editBudgetCategory,
  graphData,
  getReviewLink,
  addManualBookings,
  SendReviewMail,
  AddReview,
  dashboard,
  PinUnPin,
  editBooking,
  generateInvitationLink,
  approvePayment,
  getReviews,
  getAllStores,
  getAllBlogCat,
  getChatMembers,
  addBudgetCategory,
  getStoreList,
  getBudgetById,
  deleteBudgetCategory,
  Likes,
  AddComments,
 
  getAllBookings,
  allRead,
  addChatMember,
  rejectOffer,
  deleteAccount,
  getAllCountries,
  getAllAreas,
  getAllCities,
  resendOTP,
  deleteEvent,
  editEventBudget,
  forgetPassword,
  getEventBudget,
  getvendorquestionsadmin,
  getCategoriesWithQuestions,
  getBlogs,
  getCitiesWithCountry,
  getReligions,
  getChats,
  getThemes,
  getBlogsDetails,
  getQuestions,
  getQuestionsWeb,
  getServiceTypes,
  getAttribute,
  getSubAttribute,
  getCities,
  getEthnics,
  getTask,
  getUserEventsTasks,
  getSubTask,
  getGuest,
  getFavourite,
  getEvent,
  getMyBlogs,
  getMyBlogDetails,
  getLatestBlogs,
  getTopBlogs,
  getServices,
  getMyProfile,
  updateUserProfile,
  socialLogin,
  newPassword,
  signupUser,
  completeProfile,
  completeSignUp,
  updatePassword,
  addTask,
  editTask,
  deleteTask,
  addSubTask,
  editSubTask,
  addGuest,
  addGuestCSV,
  editGuest,
  deleteGuest,
  Firstlogin,
  addToMyBlog,
  removeFromPin,
  editEvent,
  addEvent,
  getCurrentEventDetails,
  getUserEventList,
  updatePrivacy,
  updateTerms,
  userLogout,
  adminLogout,
} from "../slices/userSlice";
export const extraReducers = (builder) => {
  builder

    // Sign In
    .addCase(signinUser.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(signinUser.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
if(action.payload.data.isVerify==true){

  localStorage.setItem("user", JSON.stringify(action.payload.data));
  state.user = action.payload.data;
  console.log(state.user);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${action.payload.data.token}`;

  state.error = null;
}
    })
    .addCase(signinUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

  

    // Firstlogin
    .addCase(Firstlogin.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(Firstlogin.fulfilled, (state, action) => {
      state.status = "succeeded";
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      state.user = action.payload.data;
      console.log(state.user);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.data.token}`;
      state.error = null;

    })
    .addCase(Firstlogin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      // toast.error(action?.payload?.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    })

    // completeProfile
    .addCase(completeProfile.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(completeProfile.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(completeProfile.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

   

    // completeSignUp
    .addCase(completeSignUp.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(completeSignUp.fulfilled, (state, action) => {
      state.status = "succeeded";
      // localStorage.setItem("user", JSON.stringify(action.payload.data));
      // state.user = action.payload.data;
      // console.log(state.user);
      // axios.defaults.headers.common[
      //   "Authorization"
      // ] = `Bearer ${action.payload.data.token}`;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(completeSignUp.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    //signupUser
    .addCase(signupUser.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(signupUser.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // localStorage.setItem("user", JSON.stringify(action.payload.data));
      // state.user = action.payload.data;
      // axios.defaults.headers.common[
      //   "Authorization"
      // ] = `Bearer ${action.payload.data.token}`;

      // state.error = null
    })
    .addCase(signupUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    //verify-account
    .addCase(verifyAccount.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(verifyAccount.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (action.payload.page !== "forget_password") {
        localStorage.setItem("user", JSON.stringify(action.payload.data));
        state.user = action.payload.data;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${action.payload.data.token}`;

        state.error = null;
      }
    })
    .addCase(verifyAccount.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    //resendOTP
    .addCase(resendOTP.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(resendOTP.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(resendOTP.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    //socialLogin
    .addCase(socialLogin.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(socialLogin.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

  localStorage.setItem("user", JSON.stringify(action.payload.data));
  state.user = action.payload.data;
  console.log(state.user);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${action?.payload?.data?.token}`;

  state.error = null;
    })
    .addCase(socialLogin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // Add Task
    .addCase(addTask.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addTask.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(addTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    

    // getAllBookings
    .addCase(getAllBookings.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllBookings.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      
    })
    .addCase(getAllBookings.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      
    })

    // approvePayment
    .addCase(approvePayment.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(approvePayment.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(approvePayment.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });;
    })

    // getReviews
    .addCase(getReviews.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getReviews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
     
    })
    .addCase(getReviews.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    
    })

    // getReviewLink
    .addCase(getReviewLink.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getReviewLink.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
     
    })
    .addCase(getReviewLink.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    
    })

    // SendReviewMail
    .addCase(SendReviewMail.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(SendReviewMail.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(SendReviewMail.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // inviteGuest
    .addCase(inviteGuest.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(inviteGuest.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      if(action.payload.medium!=="copyLink"){
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    })
    .addCase(inviteGuest.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      if(action.payload.medium!=="copyLink"){
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    })
    

    // AddReview
    .addCase(AddReview.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(AddReview.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(AddReview.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // addManualBookings
    .addCase(addManualBookings.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addManualBookings.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(addManualBookings.rejected, (state, action) => {
      console.log('action?.payload?', action?.payload?.data?.remainingAmount)
      state.status = "failed";
      state.error = action?.payload?.message;
      // toast.error(action?.payload?.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    })

    // editBooking
    .addCase(editBooking.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editBooking.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(editBooking.rejected, (state, action) => {
      console.log('action?.payload?', action?.payload?.data?.remainingAmount)
      state.status = "failed";
      state.error = action?.payload?.message;
      // toast.error(action?.payload?.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    })

    // addNewsLetter
    .addCase(addNewsLetter.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addNewsLetter.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(addNewsLetter.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      // toast.error(action?.payload?.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    })
    
    // allRead
    .addCase(allRead.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(allRead.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(allRead.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    
    // saveAttachment
    .addCase(saveAttachment.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(saveAttachment.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(saveAttachment.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // addChatMember
    .addCase(addChatMember.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addChatMember.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(addChatMember.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // rejectOffer
    .addCase(rejectOffer.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(rejectOffer.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(rejectOffer.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // rejectRequest
    .addCase(rejectRequest.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(rejectRequest.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(rejectRequest.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

 
  

    

   
    // Add addSubTask
    .addCase(addSubTask.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addSubTask.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(addSubTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })


    // Add Guest
    .addCase(addGuest.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addGuest.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(addGuest.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    // PinUnPin
    .addCase(PinUnPin.pending, (state, action) => {
      // state.status = "loading";
      state.error = null;
    })
    .addCase(PinUnPin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(PinUnPin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    // Likes
    .addCase(Likes.pending, (state, action) => {
      // state.status = "loading";
      state.error = null;
    })
    .addCase(Likes.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(Likes.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    // AddComments
    .addCase(AddComments.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(AddComments.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(AddComments.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    // updateProfile
    .addCase(updateProfile.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(updateProfile.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

 
    // updateUserProfile
    .addCase(updateUserProfile.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(updateUserProfile.fulfilled, (state, action) => {
      state.status = "succeeded";

      localStorage.setItem("user", JSON.stringify(action.payload.data));
      state.user = action.payload.data;
      console.log("aaa",state.user);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.data.token}`;

  state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(updateUserProfile.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })


   
    // generateInvitationLink
    .addCase(generateInvitationLink.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(generateInvitationLink.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(generateInvitationLink.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // sendGuestInvitation
    // .addCase(sendGuestInvitation.pending, (state, action) => {
    //   state.status = "loading";
    //   state.error = null;
    // })
    // .addCase(sendGuestInvitation.fulfilled, (state, action) => {
    //   state.status = "succeeded";
    //   state.error = null;
    //   toast.success(action?.payload?.message, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // })
    // .addCase(sendGuestInvitation.rejected, (state, action) => {
    //   state.status = "failed";
    //   state.error = action?.payload?.message;
    //   toast.error(action?.payload?.message, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // })



    // addToMyBlog
    .addCase(addToMyBlog.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addToMyBlog.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(addToMyBlog.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // AddGuestCSV
    .addCase(addGuestCSV.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addGuestCSV.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(addGuestCSV.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // Edit Task
    .addCase(editTask.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editTask.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(editTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

 

    // getvendorquestionsadmin
    .addCase(getvendorquestionsadmin.pending, (state, action) => {
     state.status = "loading";
     state.error = null;
   })
   .addCase(getvendorquestionsadmin.fulfilled, (state, action) => {
     state.status = "succeeded";
     state.error = null;

   })
   .addCase(getvendorquestionsadmin.rejected, (state, action) => {
     state.status = "failed";
     state.error = action?.payload?.message;
   })

    


    

    // Edit editSubTask
    .addCase(editSubTask.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editSubTask.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(editSubTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    
    // Edit editStoreFront
    .addCase(editStoreFront.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editStoreFront.fulfilled, (state, action) => {
      console.log('action.payload.data', action.payload)
      state.status = "succeeded";
      state.error = null;
      state.event_list = action.payload.data;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

    })
    .addCase(editStoreFront.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

 

    // addEvent
    .addCase(addEvent.pending, (state, action) => {
      state.submitStatus = "loading";
      state.error = null;
    })
    .addCase(addEvent.fulfilled, (state, action) => {
      state.submitStatus = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(addEvent.rejected, (state, action) => {
      state.submitStatus = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })



    // editEvent
    .addCase(editEvent.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editEvent.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(editEvent.rejected, (state, action) => {

      console.log('action.payload', action.payload)
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // updateRSVP
    .addCase(updateRSVP.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(updateRSVP.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(updateRSVP.rejected, (state, action) => {

      console.log('action.payload', action.payload)
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })


    // deleteAccount
    .addCase(deleteAccount.pending, (state, action) => {
     state.status = "loading";
   })
   .addCase(deleteAccount.fulfilled, (state, action) => {
     state.status = "succeeded";
     state.error = null;
     toast.success(action.payload.message, {
       position: toast.POSITION.TOP_RIGHT,
     });
   })
   .addCase(deleteAccount.rejected, (state, action) => {
     state.status = "failed";
     state.error = action.payload.message;
     toast.error(action.payload.message, {
       position: toast.POSITION.TOP_RIGHT,
     });
   })


    // Edit Guest
    .addCase(editGuest.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editGuest.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(editGuest.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

     // Delete Task
     .addCase(deleteTask.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteTask.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(deleteTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

     // deleteEvent
     .addCase(deleteEvent.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteEvent.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(deleteEvent.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })


     

    

     // Delete Guest
     .addCase(deleteGuest.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteGuest.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(deleteGuest.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

     // removeFromPin
     .addCase(removeFromPin.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(removeFromPin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(removeFromPin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // getCurrentEventDetails
    .addCase(getCurrentEventDetails.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
      state.current_event = null;
    })
    .addCase(getCurrentEventDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.current_event = action.payload.data;
    })
    .addCase(getCurrentEventDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      state.current_event = null;
    })

    

    // getBlogs
    .addCase(getBlogs.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getBlogs.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getBlogs.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getLatestBlogs
    .addCase(getLatestBlogs.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getLatestBlogs.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getLatestBlogs.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getBlogsDetails
    .addCase(getBlogsDetails.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getBlogsDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getBlogsDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getTopBlogs
    .addCase(getTopBlogs.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getTopBlogs.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getTopBlogs.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getServices
    .addCase(getServices.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getServices.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getServices.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    
    // getCategoriesWithQuestions
    .addCase(getCategoriesWithQuestions.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getCategoriesWithQuestions.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getCategoriesWithQuestions.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getChatMembers
    .addCase(getChatMembers.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getChatMembers.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getChatMembers.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })
  
    

    // getCitiesWithCountry
    .addCase(getCitiesWithCountry.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getCitiesWithCountry.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getCitiesWithCountry.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getReligions
    .addCase(getReligions.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getReligions.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getReligions.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getChats
    // .addCase(getChats.pending, (state, action) => {
    //   state.status = "loading";
    //   state.error = null;
    // })
    // .addCase(getChats.fulfilled, (state, action) => {
    //   state.status = "succeeded";
    //   state.error = null;

    // })
    // .addCase(getChats.rejected, (state, action) => {
    //   state.status = "failed";
    //   state.error = action?.payload?.message;
    // })

    // getEventBudget
    .addCase(getEventBudget.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getEventBudget.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getEventBudget.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getThemes
    .addCase(getThemes.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getThemes.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getThemes.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    //editEventBudget
    .addCase(editEventBudget.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editEventBudget.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(editEventBudget.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    //addBudgetCategory
    .addCase(addBudgetCategory.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addBudgetCategory.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(addBudgetCategory.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    //editBudgetCategory
    .addCase(editBudgetCategory.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editBudgetCategory.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(editBudgetCategory.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    //getBudgetById
    .addCase(getBudgetById.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getBudgetById.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      // toast.success(action?.payload?.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    })
    .addCase(getBudgetById.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      // toast.success(action?.payload?.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    })

    //deleteBudgetCategory
    .addCase(deleteBudgetCategory.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(deleteBudgetCategory.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(deleteBudgetCategory.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })


    // getQuestions
    .addCase(getQuestions.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getQuestions.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getQuestions.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getQuestionsWeb
    .addCase(getQuestionsWeb.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getQuestionsWeb.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getQuestionsWeb.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })


   

    // getServiceTypes
    .addCase(getServiceTypes.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getServiceTypes.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getServiceTypes.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAttribute
    .addCase(getAttribute.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAttribute.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getAttribute.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getSubAttribute
    .addCase(getSubAttribute.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getSubAttribute.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getSubAttribute.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAllCountries
    .addCase(getAllCountries.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllCountries.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getAllCountries.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAllStores
    .addCase(getAllStores.pending, (state, action) => {
      // state.status = "loading";
      state.error = null;
    })
    .addCase(getAllStores.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getAllStores.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAllBlogCat
    .addCase(getAllBlogCat.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllBlogCat.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getAllBlogCat.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAllVenues
    .addCase(getAllVenues.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllVenues.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getAllVenues.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAllAreas
    .addCase(getAllAreas.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllAreas.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getAllAreas.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAllCities
    .addCase(getAllCities.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllCities.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getAllCities.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getCities
    .addCase(getCities.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getCities.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getCities.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getEthnics
    .addCase(getEthnics.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getEthnics.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getEthnics.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getTask
    .addCase(getTask.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getTask.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })
   // getUserEventsTask
   .addCase(getUserEventsTasks.pending, (state, action) => {
    state.status = "loading";
    state.error = null;
  })
  .addCase(getUserEventsTasks.fulfilled, (state, action) => {
    state.status = "succeeded";
    state.user_events_tasks= action.payload.data
    state.error = null;

  })
  .addCase(getUserEventsTasks.rejected, (state, action) => {
    state.status = "failed";
    state.error = action?.payload?.message;
  })
    // getSubTask
    .addCase(getSubTask.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getSubTask.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getSubTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getGuest
    .addCase(getGuest.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getGuest.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getGuest.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getFavourite
    .addCase(getFavourite.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getFavourite.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getFavourite.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

  


    // getEvent
    .addCase(getEvent.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getEvent.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getEvent.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

   


    // getMyBlogs
    .addCase(getMyBlogs.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getMyBlogs.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getMyBlogs.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getMyBlogDetails
    .addCase(getMyBlogDetails.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getMyBlogDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getMyBlogDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })


 

    
   

    // getUserEventList
    .addCase(getUserEventList.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
   
    })
    .addCase(getUserEventList.fulfilled, (state, action) => {
      console.log("action.payload is =>",action?.payload);
      state.event_list = action.payload.data;
      state.status = "succeeded";
      state.error = null;
      // state.current_event = action.payload.data[0];
    })
    .addCase(getUserEventList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      // state.current_event = null;
    })

    // getStoreList
    .addCase(getStoreList.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
   
    })
    .addCase(getStoreList.fulfilled, (state, action) => {
     console.log('action.payload.data', action.payload.data)
      state.event_list = action.payload.data;
      state.status = "succeeded";
      state.error = null;
      console.log("action.payload is =>",state.event_list );
      // state.current_event = action.payload.data[0];
    })
    .addCase(getStoreList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      // state.current_event = null;
    })

   

    //forgetPassword
    .addCase(forgetPassword.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(forgetPassword.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(forgetPassword.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    //newPassword
    .addCase(newPassword.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(newPassword.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(newPassword.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

    // Dashboard
    .addCase(dashboard.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(dashboard.fulfilled, (state, action) => {
      console.log('action.payload.data99', action.payload)
      state.status = "succeeded";
      state.error = null;

      // console.log("action.payload.data :>> ", action.payload);
      // state.dashboard = {
      //   profileCount: action.payload.profileCount,
      //   phoneCount: action.payload.phoneCount,
      //   websiteCount: action.payload.websiteCount,
      //   leadsCount: action.payload.leadsCount,
      //   bookingsCount: action.payload.bookingsCount,
      //   reviewsCount: action.payload.reviewsCount,
      //   reviewsTotalCount: action.payload.reviewsTotalCount,
      // };
    })
    .addCase(dashboard.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // graphData
    .addCase(graphData.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(graphData.fulfilled, (state, action) => {
      console.log('action.payload.data99', action.payload)
      state.status = "succeeded";
      state.error = null;

      // console.log("action.payload.data :>> ", action.payload);
      // state.dashboard = {
      //   profileCount: action.payload.profileCount,
      //   phoneCount: action.payload.phoneCount,
      //   websiteCount: action.payload.websiteCount,
      //   leadsCount: action.payload.leadsCount,
      //   bookingsCount: action.payload.bookingsCount,
      //   reviewsCount: action.payload.reviewsCount,
      //   reviewsTotalCount: action.payload.reviewsTotalCount,
      // };
    })
    .addCase(graphData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // update Password
    .addCase(updatePassword.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(updatePassword.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .addCase(updatePassword.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })


    // Log Out
    .addCase(userLogout.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(userLogout.fulfilled, (state, action) => {
      toast.success(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      localStorage.clear();
      state.status = "succeeded";
      state.error = null;
      state.user = null;
      state.event_list= null;
      state.terms = null;
      state.privacy = null;
      state.user_events_tasks = null;
      state.unread_messages = null;
      state.dashboard = null;
      state.socket = null;
    })
    .addCase(userLogout.rejected, (state, action) => {
      toast.error(action?.payload?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      state.status = "failed";
      state.error = action?.payload?.message;
    })

   

    // Get TcPp
    .addCase(terms.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(terms.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.terms = action.payload;
    })
    .addCase(terms.rejected, (state, action) => {
      console.log("action.payload :>> ", action.payload);
      state.status = "failed";
      state.error = action?.payload?.message;
    })
    // Get TcPp
    .addCase(privacy.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(privacy.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.privacy = action.payload;
    })
    .addCase(privacy.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // Update TcPp
    .addCase(updateTerms.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(updateTerms.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.privacy = action.payload;
      toast.success(action?.payload?.message);
    })
    .addCase(updateTerms.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })
    // Update TcPp
    .addCase(updatePrivacy.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(updatePrivacy.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.terms = action.payload;
      toast.success(action?.payload?.message);
    })
    .addCase(updatePrivacy.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    });
};
