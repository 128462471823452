import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getEventList,
  editStoreFront,
  getServices,
  getServiceTypes,
  getEthnics,
} from "../../store/slices/userSlice";

import Sidebar from "../../components/Layout/SidebarVendor";
import StoreNavBar from "./StoreNavBar";
import { useTranslation } from "react-i18next";

const StoreFront = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const myEvents = useSelector(getEventList);



  //static data from database
  const [services, setServices] = useState(null);
  const [servicetypes, setservicetypes] = useState(null);
  const [ethnics, setEthnics] = useState(null);
  const [pricing, setpricing] = useState("");

  //updated information of user
  const [showcategory, setshowcategory] = useState("");
  const [showsubcategory, setshowsubcategory] = useState("");
  const [store_name, setstore_name] = useState("");
  const [description, setdescription] = useState("");
  const [overview, setoverview] = useState("");
  const [weddings_hosted, setweddings_hosted] = useState("");
  const [team_members, setteam_members] = useState("");
  const [min_price, setmin_price] = useState("");
  const [max_price, setmax_price] = useState("");
  const [prefered_services, setPreferedServices] = useState([]);
  const [prefered_typeofservices, setprefered_typeofservices] = useState([]);
  const [insurance, setinsurance] = useState("");
  const [business_attributes, setbusiness_attributes] = useState([]);
  const [business_ethnics, setbusiness_ethnics] = useState([]);
  const [business_startdate, setbusiness_startdate] = useState("");


  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function handleDateChange(e) {
    const inputDate = e.target.value;
    const currentDate = getCurrentDate();

    if (inputDate > currentDate) {
      e.target.value = currentDate;
    }
  }



  const numberofWeddings = [
    {
      id: 1,
      text: '<10',
    },
    {
      id: 2,
      text: '10-25',
    },
    {
      id: 3,
      text: '25-30',
    },
    {
      id: 4,
      text: '50+',
    },
  ];
  const numberofTeam = [
    {
      id: 1,
      text: '1-2',
    },
    {
      id: 2,
      text: '3-10',
    },
    {
      id: 3,
      text: '11-25',
    },
    {
      id: 4,
      text: '25+',
    },
  ];
  const BusinessAttributes = [
    {
      id: 1,
      text: 'Emarati-Owned',
    },
    {
      id: 2,
      text: 'Arab-Owned',
    },
    {
      id: 3,
      text: 'Woman-Owned',
    },
    {
      id: 4,
      text: 'Asian-Owned',
    },
  ];




  const Services = async () => {
    try {
      setServices(null);
      const response = await dispatch(getServices()).unwrap();
      console.log('response4343455', response)
      setServices(response?.data);
    } catch (rejectedValueOrSerializedError) { }
  };


  const Ethnics = async () => {
    try {
      setEthnics(null);
      const response = await dispatch(getEthnics()).unwrap();
      console.log("abc", response?.data);
      setEthnics(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const ServiceTypes = async (id) => {
    try {
      console.log('idzaaazazazazaz', id)
      setservicetypes(null);
      const response = await dispatch(getServiceTypes(id)).unwrap();

      setservicetypes(response?.data);
    } catch (rejectedValueOrSerializedError) { }
  };



  useEffect(() => {

    ServiceTypes(prefered_services);

  }, [prefered_services]);


  useEffect(() => {

    Services();
    Ethnics();

  }, []);

  useEffect(() => {


    if (myEvents) {

      setshowcategory(myEvents?.prefered_services[0]?.name)
      setshowsubcategory(myEvents?.prefered_typeofservices[0]?.name)
      setstore_name(myEvents?.store_name)
      setdescription(myEvents?.description)
      setoverview(myEvents?.overview)
      setweddings_hosted(myEvents?.weddings_hosted)
      setteam_members(myEvents?.team_members)
      setmin_price(myEvents?.min_price)
      setmax_price(myEvents?.max_price)
      setPreferedServices([myEvents?.prefered_services[0]?._id])
      setprefered_typeofservices([myEvents?.prefered_typeofservices[0]?._id])
      setinsurance(myEvents?.insurance)
      setbusiness_attributes(myEvents?.business_attributes)
      setbusiness_ethnics(myEvents?.business_ethnics)
      setbusiness_startdate(myEvents?.business_startdate)
      setpricing(myEvents?.prefered_services[0]?.pricing_type)

    }


  }, [myEvents]);



  const handleEthnicChange = (eventid) => {

    if (!business_ethnics?.includes(eventid)) {
      setbusiness_ethnics([...business_ethnics, eventid]);
    } else {
      const updatedEventIds = business_ethnics?.filter((id) => id !== eventid);
      setbusiness_ethnics(updatedEventIds);
    }
  };


  console.log('prefered_typeofservices', prefered_typeofservices)
  console.log('prefered_services', prefered_services)


  const handleSave = async (e) => {
    e.preventDefault();



    try {

      const requestData = {
        _id: myEvents?._id,
        path: location.pathname,
        page: 1,
        store_name,
        description,
        overview,
        weddings_hosted,
        team_members,
        min_price,
        max_price,
        prefered_services: JSON.stringify(prefered_services),
        prefered_typeofservices: JSON.stringify(prefered_typeofservices),
        insurance,
        business_attributes: JSON.stringify(business_attributes),
        business_ethnics: JSON.stringify(business_ethnics),
        business_startdate,
        pricing,
      };

      const nav = await dispatch(
        editStoreFront(requestData)
      ).unwrap();

      if (nav) {
        navigate("/additional-details")
      }



    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  return (
    <>
      <section className="wf-vendor-business-details">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* <StoreNavBar /> */}
              {/* <div className="d-flex gap-3 align-items-center mb-4">
                      <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/business-details-icon.svg`} alt="" className="events-icon" />
                      <h2 className="font-size-36 playfair-font color-black mb-0">{t('Business')} {t('Details')}</h2>
                    </div> */}
              <h2 className="font-size-30 dm-font purple-color mb-4 fw-normal">{t('Business')} {t('Details')}</h2>
              <div className="row">
                <div className="col-md-12">
                  <div className="wf-shadow-2 white-bg p-4 rounded mb-3">
                    <div className="row">
                      <div class="col-xl-6 col-md-12">
                        <div class="form-floating wf-input wf-input-101 mb-3">
                          <input type="text" class="form-control fw-medium" id="business-name" placeholder="name"
                            onChange={(e) => setstore_name(e.target.value)} defaultValue={store_name} autocomplete="name" required />
                          <label for="business-name" className="text-uppercase">{t('Business')} {t('Name')}</label>
                        </div>
                      </div>
                      <div class="col-xl-6 col-md-12">
                        <div class="dropdown mb-3 country-city-dropdown vendor-cat-dropdown edit-page">
                          <a className="btn dropdown-toggle fw-medium" data-bs-toggle="dropdown" aria-expanded="false">
                            {showcategory ? showcategory : <>{t('BusinessType')}</>}
                          </a>
                          <ul className="dropdown-menu">
                            <li value={""} onClick={() => { setPreferedServices([]); setpricing(""); setshowcategory("") }} className="country cursor-pointer">
                              <a>
                                <span style={{ marginLeft: "10px" }}>{t('BusinessType')}</span>
                              </a>
                            </li>
                            {services?.length > 0 ? services?.map((s) => (
                              <li value={s._id} onClick={() => { setPreferedServices([s._id]); setpricing(s.pricing_type); setshowcategory(s?.name) }} key={s.code} className="country cursor-pointer">
                                <a id={s?.id}>
                                  <img src={`${process.env.REACT_APP_IMGURL}${s.imageName}`} width="25" />
                                  <span style={{ marginLeft: "10px" }}>{s?.name}</span>
                                </a>
                              </li>
                            )) : null}
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-7 col-md-12">
                        <div class="dropdown mb-3 country-city-dropdown vendor-cat-dropdown edit-page">
                          <a className="btn dropdown-toggle fw-medium" data-bs-toggle="dropdown" aria-expanded="false">
                            {showsubcategory ? showsubcategory : "Business Sub Service"}
                          </a>
                          <ul className="dropdown-menu">
                            <li value={""} onClick={() => { setprefered_typeofservices([]); setshowsubcategory("") }} className="country cursor-pointer">
                              <a>
                                <span style={{ marginLeft: "10px" }}>{t('BusinessType')}</span>
                              </a>
                            </li>
                            {servicetypes?.length > 0 ? servicetypes[0]?.subcategories?.map((s) => (
                              <li value={s._id} onClick={() => { setprefered_typeofservices([s._id]); setshowsubcategory(s?.name) }} key={s.code} className="country cursor-pointer">
                                <a id={s?.id}>
                                  {/* <img src={`${process.env.REACT_APP_IMGURL}${s.imageName}`} width="25" /> */}
                                  <span style={{ marginLeft: "10px" }}>{s?.name}</span>
                                </a>
                              </li>
                            )) : null}
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-5 col-md-12">
                        <div class="form-floating wf-input wf-input-101 mb-3">
                          <input type="date" class="form-control fw-medium" id="floatingInput" placeholder="date" autocomplete="date"
                            onChange={(e) => setbusiness_startdate(e.target.value)} onBlur={handleDateChange} max={getCurrentDate()} defaultValue={business_startdate} required />
                          <label for="floatingInput" className="text-uppercase">{t('OperatingSince')}</label>
                        </div>
                      </div>
                      <div class="col-xl-6 col-md-12">
                        <p className="font-size-20 dm-font color-black mb-2">{t('NumberofWeddingsHosted')}</p>
                        <div className="d-flex gap-2 flex-wrap mb-4">

                          {numberofWeddings.map((data) => (
                            <>
                              <input checked={weddings_hosted == data.text ? true : false} onChange={() => setweddings_hosted(data.text)} type="radio" name="wedding-hosts" class="btn-check" id={data.text} />
                              <label class="btn wf-select-btn" for={data.text}>{data.text}</label>
                            </>
                          ))}
                        </div>
                      </div>
                      <div class="col-xl-6 col-md-12">
                        <p className="font-size-20 dm-font color-black mb-2">{t('NumberofTeamMembers')}</p>
                        <div className="d-flex gap-2 flex-wrap mb-4">
                          {numberofTeam.map((data) => (
                            <>
                              <input checked={team_members == data.text ? true : false} onChange={() => setteam_members(data.text)} type="radio" name="team-members" class="btn-check" id={data.text} />
                              <label class="btn wf-select-btn" for={data.text}>{data.text}</label>
                            </>
                          ))}
                        </div>
                      </div>
                      <div class="col-xl-6 col-md-12">
                        <p className="font-size-20 dm-font color-black mb-2">{t('BusinessHoldsPublicInsurance')}</p>
                        <div className="d-flex gap-2 flex-wrap">
                          <input checked={insurance == "yes" ? true : false} onChange={(e) => setinsurance(e.target.value)} type="radio" name="public-insurance" class="btn-check" id="yess" value="yes" />
                          <label class="btn wf-select-btn" for="yess">yes</label>

                          <input checked={insurance == "no" ? true : false} onChange={(e) => setinsurance(e.target.value)} type="radio" name="public-insurance" class="btn-check" id="noo" value="no" />
                          <label class="btn wf-select-btn" for="noo">no</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wf-shadow-2 white-bg p-4 rounded mb-4">

                    <p className="font-size-20 dm-font color-black mb-3">{t('BusinessAttributes')}</p>
                    <div class="d-flex gap-2 flex-wrap mb-4">
                      {BusinessAttributes.map((data) => (
                        <>
                          <input checked={business_attributes == data.text ? true : false} onChange={(e) => setbusiness_attributes([e.target.value])} type="radio" name="business-att" class="btn-check" id={`${data.text}${data.id}`} value={data.text} />
                          <label class="btn wf-select-btn" for={`${data.text}${data.id}`}>{data.text}</label>
                        </>
                      ))}
                    </div>
                    <p className="font-size-20 dm-font color-black mb-3">{t('BusinessServicesPriceRange')}</p>
                    <div class="d-flex gap-2 mb-4">

                      <input class="form-control form-control-sm wf-input-sm" type="number" placeholder="MIN" onChange={(e) => setmin_price(e.target.value)}
                        defaultValue={min_price && min_price} aria-label="Min Price" />

                      <input class="form-control form-control-sm wf-input-sm" type="number" placeholder="MAX" defaultValue={max_price && max_price}
                        onChange={(e) => setmax_price(e.target.value)} aria-label="Max Price" />
                    </div>
                    <p className="font-size-20 dm-font color-black mb-3">{t('WouldYouConsider')}?</p>
                    <div className="d-flex gap-2 flex-wrap">
                      {ethnics?.map((data) => (
                        <>
                          <input checked={business_ethnics?.includes(data._id) ? true : false} onChange={() => handleEthnicChange(data._id)} type="radio" name="wedding-type" class="btn-check" id={data.name} />
                          <label class="btn wf-select-btn" for={data.name}>{data.name}</label>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="wf-shadow-2 white-bg p-4 rounded mb-4">
                    <div className="row">
                      <div class="col-xl-6 col-md-12">
                        <div class="form-floating wf-textarea wf-textarea-101 mb-3">
                          <textarea onChange={(e) => setoverview(e.target.value)} defaultValue={overview} class="form-control" placeholder="Brief Overview" id="brief-overview" style={{ "height": "400px" }}></textarea>
                          <label style={{ whiteSpace: 'pre-wrap' }} for="brief-overview" className="text-uppercase">{t('BriefOverview')}</label>
                        </div>
                      </div>
                      <div class="col-xl-6 col-md-12">
                        <div class="form-floating wf-textarea wf-textarea-101 mb-3">
                          <textarea class="form-control" placeholder="Detailed Description" onChange={(e) => setdescription(e.target.value)} id="Detailed-Description"
                            defaultValue={description} style={{ "height": "400px" }}></textarea>
                          <label style={{ whiteSpace: 'pre-wrap' }} for="Detailed-Description" className="text-uppercase">{t('DetailedDescription')}</label>
                        </div>
                        <div className="text-end">
                          <button onClick={handleSave} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">{t('Save')}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </section>
          </div>
        </div>
      </section>

    </>
  );
};

export default StoreFront;