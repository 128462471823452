import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { signupUser,getAllCountries,getServices } from "../../store/slices/userSlice";
import { toast } from "react-toastify";
import jwtDecode from 'jwt-decode';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/Layout/LanguageSwitcher";
import i18n from "../../components/i18n";
// import jwt from 'jsonwebtoken';



const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setdata] = useState(location?.state?.data)


  // const test= jwt.verify()

  const handleClick = async () => {
   
    window.open(`${process.env.REACT_APP_APIURL}terms_and_conditions`,"_blank")
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(!tc){
        toast.error("Please accept all terms and conditions")
      }
      else{
       
      const su = await dispatch(
        signupUser({ name, businessName, prefered_services, email, password, phone, country, confirmPassword, own_wedding, role: "vendor", user_purpose: "vendor" })
      ).unwrap();
      console.log('su', su)
      if(su){
      navigate("/verify-account", { state: { user_id: su.data, email: email } });
      }
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getValuesofCountries=()=>{
  
    const abc= countries&&countries?.find((c)=>c._id==country)
    setshowcountry(abc?.name)

    const index = countries?.findIndex(item => item._id === country);
  
    if (index !== -1) {
      const objectToMove = countries?.splice(index, 1)[0];
      countries?.unshift(objectToMove); 
    }
  }

 
 

 
  
  
  const [services, setServices] = useState(null);
  const [showcategory, setshowcategory] = useState("");
  const [prefered_services, setPreferedServices] = useState([]);
  const [id, setid] = useState("");
  const [toggle, settoggle] = useState(false);
  const [countries, setcountries] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [country, setcountry] = useState("");
  const [showcountry, setshowcountry] = useState("");
  const [own_wedding, setown_wedding] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setphone] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [tc, settc] = useState(false);
  const [isSecureEntry, setisSecureEntry] = useState(true)
  const [isSecureEntry1, setisSecureEntry1] = useState(true)

  


  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Services = async () => {
    try {
     
      setServices(null);
      const response = await dispatch(getServices()).unwrap();
      setServices(response?.data);
    } catch (rejectedValueOrSerializedError) {}
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
        Countries();
        Services();
    }
    return () => {
      mount = false;
    };
  }, []);
  
  useEffect(() => {
 
   
      getValuesofCountries();
    
  }, [countries]);
  useEffect(() => {
    if(data){
      setPreferedServices(data?._id)
      setshowcategory(data?.name)
    }
  }, [location.pathname]);

  const currentLanguage = i18n.language;

  console.log(data, "data");

  return (
    <>
    <section class="authentication-bg">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong border-0 rounded" >
          <div class="card-body py-5 px-md-5 px-4">

          <Link class="navbar-brand mt-0 mt-lg-0" to={`${process.env.REACT_APP_WEBURL}`}>
          <img className="mx-auto d-block auth-logo mb-5 wf-logo-card" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-arabic.png' : 'logo.svg'}`} alt="Weddified" width={currentLanguage === 'ar' ? '240px' : '270px'} />
          </Link>
            <form onSubmit={handleSubmit}>
            <div class="form-floating wf-input wf-input-101 mb-3">
              <input type="text" class="form-control" id="floatingInput" placeholder="name" 
              onChange={(e) => setName(e.target.value)} defaultValue={name} autocomplete="name" maxLength={40} required />
              <label for="floatingInput">{t('YourFullName')}</label>
              {/* <div class="invalid-feedback d-block">Enter Full Name</div> */}
            </div>
            <div class="form-floating wf-input wf-input-101 mb-3">
              <input type="text" class="form-control" id="floatingInput" placeholder="business name" 
              onChange={(e) => setBusinessName(e.target.value)} defaultValue={businessName} autocomplete="name" maxLength={40} required />
              <label for="floatingInput">{t('YourBusinessName')}</label>
              {/* <div class="invalid-feedback d-block">Enter Business Name</div> */}
            </div>
            <div class="dropdown mb-3 country-city-dropdown">
      <a className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
      {showcategory ? showcategory : <>{t('BusinessType')}</>}
      </a>
      <ul className="dropdown-menu">
        <li value={""} onClick={() => {setPreferedServices([]); setshowcategory("")}} className="country cursor-pointer">
          <a disabled>                
            <span style={{ marginLeft: "10px" }}>{t('BusinessType')}</span>
          </a>
        </li>
          {services?.length>0?services?.filter((value)=> value?.slug!=="wedding-planner")?.map((s) => (
        <li value={s._id} onClick={() => {setPreferedServices(s._id);  setshowcategory(s?.name)}} key={s.code} className="country cursor-pointer">
          <a id={s?.id}>
            <img src={`${process.env.REACT_APP_IMGURL}${s.imageName}`} width="25" />
            <span style={{ marginLeft: "10px" }}>{s?.name}</span>
          </a>
        </li>
        )) : null}
      </ul>
    </div>
    {/* <div class="invalid-feedback d-block">Select Business Type</div> */}
            <div class="form-floating wf-input wf-input-101 mb-3">
              <input disabled={id?true:false} type="email" class="form-control" defaultValue={email} id="floatingInput" placeholder="name@example.com" 
              onChange={(e) => setEmail(e.target.value)} autocomplete="email" maxLength={320} required />
              <label for="floatingInput">{t('YourEmailAddress')}</label>
              {/* <div class="invalid-feedback d-block">Enter Email Address</div> */}
            </div>
            <div class="form-floating wf-input wf-input-101 mb-3">
              <input disabled={id?true:false} type="phone" class="form-control" defaultValue={phone} id="floatingInput" placeholder="" 
              onChange={(e) => setphone(e.target.value)} autocomplete="phone" maxLength={320} required />
              <label for="floatingInput">{t('Phone')}</label>
              {/* <div class="invalid-feedback d-block">Enter Email Address</div> */}
            </div>
            <div class="form-floating wf-input wf-input-101 mb-3">
              <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}
                  type={isSecureEntry ? "password" : "text"} autocomplete="password" maxLength={128} required />
              <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
              <label for="floatingPassword">{t('Password')}</label>
              {/* <div class="invalid-feedback d-block">Enter Password</div> */}
            </div>
            <div class="form-floating wf-input wf-input-101 mb-3">
              <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setconfirmPassword(e.target.value)}
                  type={isSecureEntry1 ? "password" : "text"} autocomplete="password" maxLength={128} required />
              <i onClick={() => { setisSecureEntry1((prev) => !prev) }} className={`fa-solid ${isSecureEntry1 ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
              <label for="floatingPassword">{t('Confirm')} {t('Password')}</label>
              {/* <div class="invalid-feedback d-block">Enter Confirm Password</div> */}
            </div>
            <div onClick={()=>settoggle(!toggle)}>
            <div class="dropdown mb-3 country-city-dropdown">
              <a className="btn dropdown-toggle" show  role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {country?showcountry: <>{t('CountryofResidence')}</>}
              </a>

              <ul className={`dropdown-menu ${toggle&&"show"}`}>
              <li onClick={()=>{setcountry("")}} className="country cursor-pointer">
                <a>                
                  <span style={{ marginLeft: "10px" }}>{t('CountryofResidence')}</span>
                </a>
              </li>
      {countries?.length>0&&countries.map(country => (
        <li onClick={()=>{setcountry(country._id);setshowcountry(country.name)}} key={country.code} className="country cursor-pointer">
          <a >
            <img src={`${process.env.REACT_APP_IMGURL}${country.imageName}`} width="25" />
            <span style={{ marginLeft: "10px" }}>{country.name}</span>
          </a>
        </li>
      ))}
    </ul>
    </div>
    {/* <div class="invalid-feedback d-block">Select Country of Residence</div> */}

    </div>
            
            <div class="form-check wf-signup-checkbox mb-3">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={tc} onChange={()=>settc(!tc)} />
              <label class="form-check-label" for="flexCheckDefault">
              {t('Acceptall')} <a className="white-color link-underline">{t('Terms&Conditions')}</a>
              </label>
              </div>
            <div class="d-grid gap-2 mt-2">
              <button class="btn wf-purple-btn wf-btn btn-lg" type="submit">{t('SignUp')}</button>
            </div>

            </form>
          </div>
          <div class="card-footer wf-auth-card-footer">
            <div className="text-center">
            <a>{t('AlreadyhaveaWeddifiedaccount')}? <b className="pink-color cursor-pointer" onClick={() => navigate("/login")}>{t('Login')}</b></a>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
<p className="d-none"> <LanguageSwitcher /> </p>
    </>
  );
};

export default SignUp;