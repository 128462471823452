import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getEventList, editStoreFront
} from "../../store/slices/userSlice";
import Sidebar from "../../components/Layout/SidebarVendor";
import StoreNavBar from "./StoreNavBar";
import { useTranslation } from "react-i18next";


const ContactDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const myEvents = useSelector(getEventList);


  const [contact_person, setcontact_person] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [website, setwebsite] = useState("");



  const handleSave = async (e) => {
    e.preventDefault();



    try {

      const requestData = {
        _id: myEvents?._id,
        path: location.pathname,
        contact_person: contact_person,
        phone: phone,
        email: email,
        mobile: mobile,
        website: website,
        whatsapp: whatsapp
      };

      const nav = await dispatch(
        editStoreFront(requestData)
      ).unwrap();

      if (nav) {
        navigate("/location-and-maps")
      }



    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {


    if (myEvents) {

      setcontact_person(myEvents?.contact_person);
      setphone(myEvents?.phone);
      setemail(myEvents?.email);
      setmobile(myEvents?.mobile);
      setwebsite(myEvents?.website);
      setwhatsapp(myEvents?.whatsapp);
    }


  }, [myEvents]);


  // Social_Networks

  const [facebook, setfacebook] = useState("");
  const [twitter, settwitter] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [instagram, setinstagram] = useState("");
  const [youtube, setyoutube] = useState("");
  const [pinterest, setpinterest] = useState("")


  const handleSavesocial = async (e) => {
    e.preventDefault();



    try {

      const requestData = {
        _id: myEvents?._id,
        path: location.pathname,
        facebook: facebook,
        twitter: twitter,
        linkedin: linkedin,
        instagram: instagram,
        youtube: youtube,
        pinterest: pinterest
      };

      const nav = await dispatch(
        editStoreFront(requestData)
      ).unwrap();

      if (nav) {
        navigate("/preferred-suppliers")
      }



    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {


    if (myEvents) {

      setfacebook(myEvents?.facebook == "null" ? "" : myEvents?.facebook);
      settwitter(myEvents?.twitter == "null" ? "" : myEvents?.twitter);
      setlinkedin(myEvents?.linkedin == "null" ? "" : myEvents?.linkedin);
      setinstagram(myEvents?.instagram == "null" ? "" : myEvents?.instagram);
      setyoutube(myEvents?.youtube == "null" ? "" : myEvents?.youtube);
      setpinterest(myEvents?.pinterest == "null" ? "" : myEvents?.pinterest);
    }


  }, [myEvents]);

  // Social_Networks

  return (

    <>
      <section className="wf-vendor-contact-details">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* <StoreNavBar /> */}
              {/* <div className="d-flex gap-3 align-items-center mb-4">
                  <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/contact-details-icon.svg`} alt="" className="events-icon" />
                  <h2 className="font-size-36 playfair-font color-black mb-0">{t('Contact')} {t('Details')}</h2>
                </div> */}
              <h2 className="font-size-30 dm-font purple-color mb-4 fw-normal">{t('Contact')} {t('Details')}</h2>
              <div className="wf-shadow-2 white-bg p-4 rounded mb-4">
                <div className="row gy-4 gx-4 mb-4">
                  <div className="col-xl-6 col-md-12">
                    <div class="form-floating wf-input wf-input-101">
                      <input onChange={(e) => setcontact_person(e.target.value)} defaultValue={contact_person && contact_person} type="text" class="form-control fw-medium" id="Contact-person" placeholder="name"
                        autocomplete="name" required />
                      <label for="Contact-person" className="text-uppercase">{t('Contactperson')}</label>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <div class="form-floating wf-input wf-input-101">
                      <input onChange={(e) => setphone(e.target.value)} defaultValue={phone && phone} type="tel" class="form-control fw-medium" id="Phone" placeholder="Phone"
                        autocomplete="Phone" required />
                      <label for="Phone" className="text-uppercase">{t('Phone')}</label>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <div class="form-floating wf-input wf-input-101">
                      <input onChange={(e) => setemail(e.target.value)} defaultValue={email && email} type="email" class="form-control fw-medium" id="email" placeholder="email"
                        autocomplete="email" required />
                      <label for="email" className="text-uppercase">{t('Email')}</label>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <div class="form-floating wf-input wf-input-101">
                      <input onChange={(e) => setmobile(e.target.value)} defaultValue={mobile && mobile} type="tel" class="form-control fw-medium" id="Mobile" placeholder="Mobile"
                        autocomplete="Mobile" required />
                      <label for="Mobile" className="text-uppercase">{t('Mobile')}</label>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <div class="form-floating wf-input wf-input-101">
                      <input defaultValue={website} onChange={(e) => setwebsite(e.target.value)} type="url" class="form-control fw-medium" id="Website" placeholder="Website"
                        autocomplete="website" required />
                      <label for="Website" className="text-uppercase">{t('Website')}</label>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <div class="form-check wf-signup-checkbox">
                      <input checked={whatsapp ? true : false} onChange={(e) => setwhatsapp(e.target.checked ? mobile : "")} defaultValue={whatsapp} class="form-check-input fw-medium" type="checkbox" value="" id="whatsapp-check" />
                      <label class="form-check-label color-grey" for="whatsapp-check">
                        {t('This number is also available on whatsapp')}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button onClick={handleSave} class="btn btn-lg wf-pink-btn wf-btn fw-bold text-uppercase py-2">{t('Save')}</button>
                </div>
              </div>

              {/* Social */}
              <div className="wf-shadow-2 white-bg p-4 rounded">
                <div className="d-flex gap-3 align-items-center mb-4">
                  {/* <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/social-network-icon.svg`} alt="" className="events-icon" /> */}
                  <h2 className="font-size-20 dm-font color-black mb-3">{t('SocialNetwork')}</h2>
                </div>
                <div className="row g-4 mb-4">
                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/facebook-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={facebook && facebook} onChange={(e) => setfacebook(e.target.value)} type="text" class="form-control" id="facebook" placeholder="Facebook" required />
                        <label for="facebook">Facebook</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/x-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={twitter && twitter} onChange={(e) => settwitter(e.target.value)} type="text" class="form-control" id="twitter" placeholder="twitter" required />
                        <label for="twitter">X</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/linkedin-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={linkedin && linkedin} onChange={(e) => setlinkedin(e.target.value)} type="text" class="form-control" id="linkedIn" placeholder="LinkedIn" required />
                        <label for="linkedIn">LinkedIn</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/insta-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={instagram && instagram} onChange={(e) => setinstagram(e.target.value)} type="text" class="form-control" id="instagram" placeholder="Instagram" required />
                        <label for="instagram">Instagram</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/youtube-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={youtube && youtube} onChange={(e) => setyoutube(e.target.value)} type="text" class="form-control" id="youtube" placeholder="Youtube" required />
                        <label for="youtube">Youtube</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/pinterest-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={pinterest && pinterest} onChange={(e) => setpinterest(e.target.value)} type="text" class="form-control" id="pinterest" placeholder="Pinterest" required />
                        <label for="pinterest">Pinterest</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-end">
                  <button onClick={handleSavesocial} class="btn btn-lg wf-pink-btn wf-btn fw-bold text-uppercase py-2">{t('Save')}</button>
                </div>
              </div>

            </section>
          </div>
        </div>
      </section>

    </>
  )
}

export default ContactDetails