import React, { useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
const mapIcon = new L.Icon({
  iconUrl: '../assets/images/map-pin-logo.svg',
  iconRetinaUrl: '../assets/images/map-pin-logo.svg',
  iconAnchor: new L.Point(0, 0),
  popupAnchor: new L.Point(16, 0),
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(32, 32),
  className: 'weddified-map-icon'
  });

const StoreMap = ({ markers, initialPosition, myEvents }) => {
  const { t } = useTranslation();


    const zoomLevel = 13;
    const mapRef = useRef(null);
    useEffect(() => {
     
      if (mapRef.current) {
        const map = mapRef.current.leafletElement;
      }
    }, [initialPosition]);

  //  const aa= [24.8607343, 67.0011364]
   

  return (
    
  
    <div>    
   {myEvents?.location?.location?(<div id="location">
          <div>    
          {initialPosition?.length==0?(null):(<MapContainer key={`${initialPosition[0]}_${initialPosition[1]}`} center={initialPosition} zoom={zoomLevel} style={{ height: '400px', width: '100%' }}>
          <TileLayer  url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"/>
          {markers?.map((marker, index) => {
            console.log('marker', markers,initialPosition)
            return(
            <Marker key={index} position={[marker.lat, marker.lng]} icon={mapIcon}>
              <Popup>{marker.name}</Popup>
            </Marker>
          )})}
          </MapContainer>)}
          </div>
        
          <div className="row mt-4">
          
          </div>
        </div>):null}  
          </div>
        
    
  )
}

export default StoreMap

