import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../context/context';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout, getEventList, getProfile, getUserEventList, getCurrentEventDetails, updatePassword, getUnreadMessagesNotification } from "../../store/slices/userSlice"
import ChangePassword from '../ChangePassword';
import Modal from 'react-modal';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const customStyles = {
  content: {
    display: "block",
    '--bs-modal-width': '30%',
  },
};
Modal.setAppElement('#root');


const NavVendor = ({fullwidth}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { SetToggleButton } = useContext(context);
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unread = useSelector(getUnreadMessagesNotification);


  const eventList = useSelector(getEventList);


  console.log('eventLisssssssst', eventList)

  const profile = useSelector(getProfile)


  const [eventNames, setEventNames] = useState([])

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }




  const handleLogout = async () => {
    try {

      console.log("first")

      await dispatch(userLogout()).unwrap()
      navigate(profile?.role == "customer" ? "/login" : "/vendor/login")

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError)
    }
  }

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 10;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>



      <header className={`${isScrolled ? 'wf-nav-sticky' : ''}`}>
        {/* <div className="container"> */}
        <nav className={`navbar navbar-expand-xl navbar-light pt-4 pb-4 wf-user-nav vendor-nav`}>

          <div className={`${fullwidth === "No" ? "container" : "container-fluid"}`}>
            <Link class="navbar-brand mt-0 me-2 mt-lg-0" to={`/`}>
              <img className="img-fluid" src="../assets/images/logo.svg" alt="Weddified" width="200px" />
              <span style={{ "marginLeft": "5px" }} className="dm-font font-size-24 fw-medium">{t('Business')}</span>
            </Link>
            <Link class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i class="bi bi-list navbar_open"></i>
              <i class="bi bi-x navbar_close"></i>
            </Link>

            <div className="collapse navbar-collapse navbar-light" id="navbarSupportedContent">
              <ul class="navbar-nav dashboard-nav m-xl-auto mb-2 mb-lg-0" style={{ "gap": "15px" }}>
                <li class="nav-item">
                  <Link className={"fw-semibold font-size-20 " + (location?.pathname == "/#" ? "nav-link active" : "nav-link")} to="https://weddified.com/suppliers">
                    {/* <img src={`${process.env.REACT_APP_FrontURL}/assets/images/dashboard-icon-v.svg`} alt="" className="nav-image" /> */}
                    {t('Suppliers')}
                  </Link>
                </li>
                <li class="nav-item">
                  <Link className={"fw-semibold font-size-20 " + (location?.pathname == "/#" ? "nav-link active" : "nav-link")} to="/#">
                    {t('Planners')}
                  </Link>
                </li>
                <li class="nav-item">
                  <Link className={"fw-semibold font-size-20 " + (location?.pathname == "/#" ? "nav-link active" : "nav-link")} to="https://weddified.com/suppliers/venue">
                    {t('Venues')}
                  </Link>
                </li>
                <li class="nav-item">
                  <Link className={"fw-semibold font-size-20 " + (location?.pathname == "/#" ? "nav-link active" : "nav-link")} to="https://weddified.com/ideas-inspiration">
                    {t('Inspire')}
                  </Link>
                </li>
              </ul>
              {profile ? <div class="d-flex align-items-center" style={{ "gap": "25px" }}>
                <div class="dropdown custom-dropdown notification">
                  <a class="dropdown-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="far fa-bell icon-notifications"></i>
                    <span class="number">5</span>
                  </a>
                  <div class="dropdown-menu" >
                    <div class="d-flex align-items-center px-2 py-1">
                      <p class="font-size-16 dm-font purple-color mb-0">{t('Notifications')}</p>
                    </div>

                    <ul class="custom-notifications">
                      <li class="unread">
                        <a href="#" class="d-flex align-items-center">
                          <div class="img mr-3">
                            <img src="https://via.placeholder.com/50x50" alt="Image" class="img-fluid" />
                          </div>
                          <p class="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br /> <span>marked the task done a day ago</span></p>
                          <span className="notification-date">Tue, 12:30am</span>
                        </a>
                      </li>

                      <li class="">
                        <a href="#" class="d-flex align-items-center">
                          <div class="img mr-3">
                            <img src="https://via.placeholder.com/50x50" alt="Image" class="img-fluid" />
                          </div>
                          <p class="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br /> <span>marked the task done a day ago</span></p>
                          <span className="notification-date">Tue, 12:30am</span>
                        </a>
                      </li>
                      <li class="">
                        <a href="#" class="d-flex align-items-center">
                          <div class="img mr-3">
                            <img src="https://via.placeholder.com/50x50" alt="Image" class="img-fluid" />
                          </div>
                          <p class="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br /> <span>marked the task done a day ago</span></p>
                          <span className="notification-date">Tue, 12:30am</span>
                        </a>
                      </li>
                      <li class="">
                        <a href="#" class="d-flex align-items-center">
                          <div class="img mr-3">
                            <img src="https://via.placeholder.com/50x50" alt="Image" class="img-fluid" />
                          </div>
                          <p class="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br /> <span>marked the task done a day ago</span></p>
                          <span className="notification-date">Tue, 12:30am</span>
                        </a>
                      </li>
                      <li class="">
                        <a href="#" class="d-flex align-items-center">
                          <div class="img mr-3">
                            <img src="https://via.placeholder.com/50x50" alt="Image" class="img-fluid" />
                          </div>
                          <p class="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br /> <span>marked the task done a day ago</span></p>
                          <span className="notification-date">Tue, 12:30am</span>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>


                <div class="wf-profile dropdown ml-2">

                  <a class="d-flex align-items-center gap-2" href="#" role="button">
                    {/* <p class="font-size-14 dm-font purple-color mb-0">{profile?.name}</p> */}
                    <div className="position-relative rounded-circle">
                      <img width="60" alt="avatar" src={profile?.imageName ? `${process.env.REACT_APP_IMGURL}${profile?.imageName}` : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" />
                      {unread?.length > 0 ? (<span class="badge pink-bg color-white rounded-circle position-absolute bottom-0 wf-profile-badge">{unread?.length}</span>) : null}
                    </div>
                  </a>

                  <div class="dropdown-menu pb-2" aria-labelledby="dropdownUser">
                    <div class="d-flex align-items-center gap-2 px-2" >
                      <div className="position-relative rounded-circle">
                        <img width="56" alt="avatar" src={profile?.imageName ? `${process.env.REACT_APP_IMGURL}${profile?.imageName}` : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" />
                      </div>
                      <p class="font-size-16 dm-font pink-color mb-0">{profile?.name}</p>
                    </div>
                    <div style={{ '--bs-dropdown-divider-bg': '#e7e7e7' }} class="dropdown-divider"></div>
                    <ul class="list-unstyled mb-0">
                      <li><Link className="dropdown-item" to="/enquiries">
                        <i class="bi bi-envelope"></i> {t('Enquiries')}
                        {unread?.length > 0 ? (<span class="badge pink-bg color-white rounded-circle position-absolute end-0 mx-2">{unread?.length}</span>) : null}
                      </Link></li>
                      <li><Link className="dropdown-item" to="/settings">
                        <i class="bi bi-person"></i> {t('Settings')}
                      </Link></li>
                      <li><Link className="dropdown-item" >
                        <i class="bi bi-translate"></i> <LanguageSwitcher />
                      </Link></li>
                      <li><Link className="dropdown-item" >
                        <i class="bi bi-question-circle"></i> {t('Help&Support')}
                      </Link></li>
                      <li><Link className="dropdown-item" onClick={handleLogout}>
                        <i class="bi bi-box-arrow-right"></i> {t('Logout')}
                      </Link></li>
                    </ul>
                  </div>
                </div>

              </div> : <div class="d-flex align-items-center">
              <button onClick={()=>navigate("/signup")} type="button" class="btn sign-up-btn">{t('SignUp')}</button>
                </div>}
            </div>


          </div>

        </nav>
        {/* </div> */}
      </header>


    </>
  )
}

export default NavVendor