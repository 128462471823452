import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import NavVendor from "./NavVendor";
import Spinner from "../Spinner";
import LoginVendor from "../../pages/Authentication/Login";
import SignUpVendor from "../../pages/Authentication/SignUpLanding";
import SignUpVendor1 from "../../pages/Authentication/SignUp1";
import SignUpVendor2 from "../../pages/Authentication/SignUp2";
import RequireAuth from "../../pages/Authentication/RequireAuth"
import NewPasswordVendor from "../../pages/Authentication/NewPassword";
import ForgetPasswordVendor from "../../pages/Authentication/ForgetPassword";
import VendorSettings from "../../pages/VendorDashboard/Settings";
import DashboardVendor from "../../pages/VendorDashboard/Dashboard";
import Chat from "../../pages/VendorDashboard/Chat";
import Bookings from "../../pages/VendorDashboard/Bookings";
import BookingDetails from "../../pages/VendorDashboard/BookingDetails";
import Reviews from "../../pages/VendorDashboard/Reviews";
import StoreAdditionalDetails from "../../pages/VendorDashboard/StoreAdditionalDetails";
import StoreBusinessDetails from "../../pages/VendorDashboard/StoreBusinessDetails";
import StoreContactDetails from "../../pages/VendorDashboard/StoreContactDetails";
import StoreFAQ from "../../pages/VendorDashboard/StoreFAQ";
import StoreLocationAndMaps from "../../pages/VendorDashboard/StoreLocationAndMaps";
import StorePhotosAndVideos from "../../pages/VendorDashboard/StorePhotoAndVideo";
import StorePreferredSuppliers from "../../pages/VendorDashboard/StorePreferredSuppliers";
import StorePricingAndDeals from "../../pages/VendorDashboard/StorePricingAndDeals";
import StoreSocialNetwork from "../../pages/VendorDashboard/StoreSocialNetwork";
import StoreWeddifiedBadge from "../../pages/VendorDashboard/StoreWeddifiedBadge";
import { useSelector } from "react-redux";
import { getUserStatus, getUsertoken, getProfile } from "../../store/slices/userSlice";
// import TermsAndConditions from "../../pages/Authentication/TermsAndConditions";
// import PrivacyPolicy from "../../pages/Authentication/PrivacyPolicy";
import { context } from "../../context/context";
import Analytics from "../../pages/VendorDashboard/Analytics";
import RequestReviews from "../../pages/VendorDashboard/RequestReview";
import FAQ from "../../pages/VendorDashboard/StoreFAQ";
import { useTranslation } from "react-i18next";
import { Reviewsnew } from "../../pages/VendorDashboard/Reviewsnew";
import Businesspage from "../../pages/VendorDashboard/Businesspage";
import NewBookingDetails from "../../pages/VendorDashboard/NewBookingDetails";
import Featured from "../../pages/VendorDashboard/Featured";





const Layout = () => {
  const { i18n } = useTranslation();
  const { isLoading } = useContext(context);
  const status = useSelector(getUserStatus);
  const authtoken = useSelector(getUsertoken);
  const profile = useSelector(getProfile);


  useEffect(() => {
    // Update lang and dir attributes based on the selected language
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    if (i18n.language === 'ar') {
      document.body.classList.add('rtl-arabic');
    } else {
      document.body.classList.remove('rtl-arabic');
    }
  }, [i18n.language]);

  console.log('status', status)

  return (
    <>
      {/* {status === "loading"  ? 
      <Spinner /> 
      : null} */}

      <BrowserRouter>
        <Content authtoken={authtoken} />
      </BrowserRouter>

    </>
  );
};


const Content = ({ authtoken }) => {
  var dash = "";
  const location = useLocation();
  const p = location.pathname.split('/');
  const p1 = p[p.length - 1];

  for (var i = p1.length - 1; i >= 0; i--) {
    if (p1[i] == '_') {
      console.log("trueeeeeeeee");
      dash = i;
      break;
    }
  }

  const t = decodeURIComponent(p1.replace(/-/g, ' '));


  function capitalizeEachWord(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  useEffect(() => {
    document.title = location.pathname == "/" && !authtoken ? "Weddified Business" : location.pathname == "/" && authtoken ? "Dashboard" : dash !== "" ? capitalizeEachWord(t.substring(0, dash)) : capitalizeEachWord(t);

  }, [dash, t]);

  useEffect(() => {
    const body = document.body;
    if (
      location.pathname === "/dashboard" || location.pathname === "/business-details" || location.pathname === "/additional-details" || location.pathname === "/contact-details"
      || location.pathname === "/location-and-maps" || location.pathname === "/faqs" || location.pathname === "/pricing-and-deals"
      || location.pathname === "/photos-and-videos" || location.pathname === "/social-network" || location.pathname === "/preferred-suppliers"
      || location.pathname === "/weddified-badge" || location.pathname === "/enquiries" || location.pathname === "/bookings" || location.pathname === "/booking-details"
      || location.pathname === "/reviews" || location.pathname === "/request-reviews" || location.pathname === "/analytics" || location.pathname === "/settings" || location.pathname === "/featured"
    ) {
      body.classList.add('wf-user-pages');
    } else {
      body.classList.remove('wf-user-pages');
    }

    return () => {
      body.classList.remove('wf-user-pages');
    };
  }, [location.pathname]);

  console.log("location.pathname", location.pathname)
  console.log("authtoken", authtoken)

  return (
    <>
      {authtoken && location.pathname === "/dashboard" || location.pathname === "/business-details" || location.pathname === "/reviewsnew" || location.pathname === "/bookingnew" || location.pathname === "/additional-details" || location.pathname === "/contact-details"
        || location.pathname === "/location-and-maps" || location.pathname === "/faqs" || location.pathname === "/businesspage" || location.pathname === "/pricing-and-deals"
        || location.pathname === "/photos-and-videos" || location.pathname === "/social-network" || location.pathname === "/preferred-suppliers"
        || location.pathname === "/weddified-badge" || location.pathname === "/enquiries" || location.pathname === "/bookings" || location.pathname === "/booking-details" || location.pathname === "/new-booking-details"
        || location.pathname === "/reviews" || location.pathname === "/request-reviews" || location.pathname === "/analytics" || location.pathname === "/settings" || location.pathname === "/featured" ?
        (<>
          <main className="wf-business-pannel">
            <NavVendor />
            <Routes>
              <Route path="*" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<RequireAuth allowedRoles={["vendor", "planner"]}><DashboardVendor /></RequireAuth>} />
              <Route path="/analytics" element={<RequireAuth allowedRoles={["vendor", "planner"]}><Analytics /></RequireAuth>} />
              <Route path="/bookings" element={<RequireAuth allowedRoles={["vendor", "planner"]}><Bookings /></RequireAuth>} />
              <Route path="/booking-details" element={<RequireAuth allowedRoles={["vendor", "planner"]}><BookingDetails /></RequireAuth>} />
              <Route path="/reviews" element={<RequireAuth allowedRoles={["vendor", "planner"]}><Reviews /></RequireAuth>} />
              <Route path="/request-reviews" element={<RequireAuth allowedRoles={["vendor", "planner"]}><RequestReviews /></RequireAuth>} />
              <Route path="/settings" element={<RequireAuth allowedRoles={["vendor", "planner"]}><VendorSettings /></RequireAuth>} />
              <Route path="/additional-details" element={<RequireAuth allowedRoles={["vendor", "planner"]}><StoreAdditionalDetails /></RequireAuth>} />
              <Route path="/business-details" element={<RequireAuth allowedRoles={["vendor", "planner"]}><StoreBusinessDetails /></RequireAuth>} />
              <Route path="/contact-details" element={<RequireAuth allowedRoles={["vendor", "planner"]}><StoreContactDetails /></RequireAuth>} />
              <Route path="/preferred-suppliers" element={<RequireAuth allowedRoles={["vendor", "planner"]}><StorePreferredSuppliers /></RequireAuth>} />
              <Route path="/faqs" element={<RequireAuth allowedRoles={["vendor", "planner"]}><StoreFAQ /></RequireAuth>} />
              <Route path="/location-and-maps" element={<RequireAuth allowedRoles={["vendor", "planner"]}><StoreLocationAndMaps /></RequireAuth>} />
              <Route path="/photos-and-videos" element={<RequireAuth allowedRoles={["vendor", "planner"]}><StorePhotosAndVideos /></RequireAuth>} />
              <Route path="/pricing-and-deals" element={<RequireAuth allowedRoles={["vendor", "planner"]}><StorePricingAndDeals /></RequireAuth>} />
              <Route path="/social-network" element={<RequireAuth allowedRoles={["vendor", "planner"]}><StoreSocialNetwork /></RequireAuth>} />
              <Route path="/weddified-badge" element={<RequireAuth allowedRoles={["vendor", "planner"]}><StoreWeddifiedBadge /></RequireAuth>} />
              <Route path="/enquiries" element={<RequireAuth allowedRoles={["vendor", "planner"]}><Chat /></RequireAuth>} />
              <Route path="/reviewsnew" element={<RequireAuth allowedRoles={["vendor", "planner"]}><Reviewsnew /></RequireAuth>} />
              <Route path="/featured" element={<RequireAuth allowedRoles={["vendor", "planner"]}><Featured /></RequireAuth>} />
              <Route path="/" element={<RequireAuth allowedRoles={["vendor", "planner"]}><Businesspage /></RequireAuth>} />
              {/* <Route path="/new-booking-details" element={<RequireAuth allowedRoles={["vendor", "planner"]}><NewBookingDetails /></RequireAuth>} /> */}
            </Routes>
          </main>
        </>
      ) : (
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/login" exact element={<LoginVendor />} />
          <Route path="/signup/" element={<SignUpVendor />} />
          <Route path="/verify-account/" element={<SignUpVendor1 />} />
          <Route path="/confirmation/" element={<SignUpVendor2 />} />
          <Route path="/forgetpassword/" element={<ForgetPasswordVendor />} />
          <Route path="/newpassword/" element={<NewPasswordVendor />} />          
          <Route path="/" element={<Businesspage />} />
        </Routes>
      )}
    </>
  );
};

export default Layout;