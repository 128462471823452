import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProfile,getStoreList } from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/Layout/LanguageSwitcher";
import i18n from "../../components/i18n";


const SignUp2 = () => {
  const { t } = useTranslation(); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);

console.log('profile', profile)

const currentLanguage = i18n.language;
  return (
    <>
    <section class="authentication-bg">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong border-0 rounded" >
          <div class="card-body py-5 px-md-5 px-4 text-center">

          <img onClick={()=>navigate("/")} className="mx-auto d-block auth-logo mb-5 wf-logo-card" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-arabic.png' : 'logo.svg'}`} alt="Weddified" width={currentLanguage === 'ar' ? '240px' : '270px'} />
          <img className="mb-4" src={"../assets/images/verified-success.svg"} />
          <p className="dm-font font-size-20 pink-color mb-0">
           <b>{t('VerifiedSuccessfully')}</b>
            </p>
            <p className="font-size-14 color-black">
            {t('Thankyou')}
            </p>
          </div>
          <div class="card-footer wf-auth-card-footer">
            <div className="text-center">
            <a
            className=""
            onClick={async() => {await dispatch(getStoreList());navigate(profile?.firstLogin?"/business-details":"/dashboard")}}
          >
            <u>{t('Proceedtodashboard')}</u>
          </a>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>   
<p className="d-none"> <LanguageSwitcher /> </p>
    </>
  );
};

export default SignUp2;