import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//  import {  useNavigate, useLocation } from "react-router-dom";
import { token,getProfile } from "./store/slices/userSlice"
import { getUserEventList,getStoreList,userLogout } from "../src/store/slices/userSlice";
import Layout from './components/Layout/Layout';
import ContextProvider from "./context/context"
import { requestForToken, messaging } from "./firebase"
import { onMessage } from "firebase/messaging"
import { setSocket } from "./store/slices/socketSlice";
import { toast } from 'react-toastify';
import axios from "axios";

 
function App() { 
  const dispatch = useDispatch()  
  const profile=useSelector(getProfile)
  // const navigate = useNavigate();



  const GetEventNames = async () => {
    try {
     
      
        await dispatch(getStoreList()).unwrap();
      
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    try { 
      dispatch(token())
      dispatch(setSocket())
      requestForToken();
     onMessage(messaging, (payload) => {
        toast(payload.notification.body)
        console.log("foreground", payload)
     })
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(()=>{
    GetEventNames()
    const selectedLanguage = localStorage.getItem('selectedLanguage');

  if (!selectedLanguage) {
    // If it doesn't exist, set it to 'en'
    localStorage.setItem('selectedLanguage', 'en');
  }

  },[])


 

  return (
    <ContextProvider >
      <Layout />
    </ContextProvider>
  );
}

export default App;
